import React, { FC } from 'react'

import { styled } from '@linaria/react'

import { WithChildren } from 'common/types'

import { ButtonLoader } from './ButtonLoader'
import { SIZES_MAP_PX } from './constants.common'
import { Size } from './types.common'

export const ButtonInner: FC<
  { size: Size; loading?: boolean } & WithChildren
> = ({ size, loading, children }) => {
  return (
    <Inner style={{ height: SIZES_MAP_PX[size] }}>
      <ButtonLoader size={size} show={loading} />
      <Content>{children}</Content>
    </Inner>
  )
}

const Inner = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Content = styled(Inner)``
